/* General styles - fonts etc */
@font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Bold'), url('../fonts/Gilroy-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Gilroy-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Light'), url('../fonts/Gilroy-Light.woff') format('woff');
}
@font-face {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Medium'), url('../fonts/Gilroy-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Regular'), url('../fonts/Gilroy-Regular.woff') format('woff');
}

body {
    font-family: 'Gilroy-Regular', sans-serif;
    font-size: 18px;
    line-height: 1.8em;
    color: #000;
}

h1, h2, h3, h4 { font-family: "Gilroy-Bold", arial, sans-serif; text-transform: uppercase; letter-spacing: 1px; }

h1 { font-size: 60px; line-height: 72px; margin-bottom: 36px; }
h2 { font-size: 45px; line-height: 56px; margin-bottom: 24px; }
h3 { font-size: 32px; line-height: 56px; margin-bottom: 0; }

p { font-size: 19px; margin-bottom: 28px; }
p.sml { font-size: 15px; opacity: 0.8; }

a {  color: #000; text-decoration: none; padding-bottom: 1px; border-bottom: 1px solid rgba(0,0,0,1); }
a:hover { border-bottom-color: rgba(0,0,0,0); }

a.btn, button {
    display: inline-block;
    padding: 28px 52px;
    background: #95CFC0;
    border: none;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-family: "Gilroy-Medium", arial, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    margin-top: 32px;
    transition: 0.5s;
    cursor: pointer;
}
button {
    padding: 18px 42px;
    margin-bottom: 8px;;
}
button.sml-btn {
    padding: 12px 25px;
}
a.btn:hover, button:hover {
    background: #CBE2DC;
}

.input.checkbox input {
    float: left;
    margin-top: 1px;
    margin-right: 8px;
}
.val-msg {
    font-size: 14px;
    color: #BE2839;
    margin-top: 5px;
}

.faded {
    opacity: 0.5;
    font-style: italic;
}

.mbtm { margin-bottom: 22px; }

/* Generic form styles */
label {
    display: block;
    opacity: 0.5;
    margin: 22px 0 5px 0;
    line-height: 18px;
    font-size: 16px;
}
input[type="text"], input[type="email"], input[type="password"], select {
    border: 1px solid #CBE2DC;
    padding: 12px 25px;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    font-size: 20px;
    font-family: "Gilroy-Medium", arial, sans-serif;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus {
    outline: none;
    border-width: 3px;
    padding: 10px 22px;
}

/* Messages and alerts */
.flash-msg {
    border-radius: 5px;
    background: #EF7B88;
    padding: 12px 18px;
    font-size: 16px;
    line-height: 1.5em;
}
.flash-msg.grn {
    background: #95CFC0;
}

.flash-msg.question-msg{
    position: absolute;
    top: 185px;
}
.flash-msg p {
    margin-bottom: 0 !important;
}

/* Header / footer */
header {
    padding: 32px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}
header .logo {
    width: 100%;
    max-width: 225px;
}
header nav {
    text-align: right;
    padding: 8px 0;
}
header nav ul {
    list-style: none;
}
header nav ul li {
    display: inline-block;
    margin: 0 0 0 20px;
}
header nav a {
    color: #000;
    text-decoration: none;
    border-bottom: none;
    font-size: 14px;
    transition: 0.5s;
    padding: 10px 22px;
}
header nav a:hover {
    opacity: 0.5;
}

footer {
    background: #CBE2DC;
    padding: 42px 0;
    text-align: center;
    clear: both;
}
footer img.logo {
    width: 160px;
    display: block;
    margin: 0 auto 24px auto;
}
footer p {
    margin: 0;
    font-size: 14px;
    font-family: "Gilroy-Light", arial, sans-serif;
}
footer p.powered {
    opacity: 0.5;
    font-variant: small-caps;
    margin-bottom: 5px;
}

/* Questionnaire intro */
section.intro {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
section.intro::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    background: linear-gradient(#B4DFD4, #A4D7C9, #95CFC0);
    z-index: -5;
}
.intro .shard-one {
    content: "";
    background: #AADACE;
    width: 150%;
    height: 1000px;
    position: absolute;
    top: -50%;
    left: -95%;
    transform: rotate(-35deg);
    z-index: -3;
}
.intro .shard-two {
    content: "";
    background: #529E8A;
    width: 150%;
    height: 1000px;
    position: absolute;
    top: -25%;
    right: -95%;
    transform: rotate(65deg);
    z-index: -3;
    opacity: 0.39;
}
.intro:after {
    content: "";
    background: #83C7B5;
    width: 150%;
    height: 1000px;
    position: absolute;
    top: 85%;
    right: -40%;
    transform: rotate(-29deg);
    z-index: -4;
    opacity: 0.55;
}

.intro .width-6.wording {
    margin-top: 250px;
}

.intro .width-6.lady {
    position: relative;
    height: 100vh;
}

.intro .width-6 img {
    width: 100%;
    max-width: 770px;
    display: block;
    margin: 250px auto 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: 0.75s;
}
.intro .width-6 img.visible { opacity: 1; }

.drivers {
    padding: 115px 0 100px 0;
}
.drivers p {
    margin-bottom: 42px;
}
.drivers .width-6.driver {
    height: 340px;
    box-sizing: border-box;
    position: relative;
    padding: 92px 56px;
    margin-bottom: 40px;
    overflow: hidden;
}
.drivers .width-6.driver h6.ball-no {
    position: absolute;
    top: 18px;
    left: 18px;
    background: rgba(255,255,255,0.5);
    width: 44px;
    height: 44px;
    border-radius: 25px;
    text-align: center;
    font-size: 22px;
    padding-top: 9px;
    font-family: "Gilroy-Bold", arial, sans-serif;
    box-sizing: border-box;
}
.drivers .width-6.driver:after { content: ""; position: absolute; z-index: -2; width: 100%; height: 100%; top: 0; left: 0; }
.drivers .width-6.driver.blue:after { background: #57AEE2; }
.drivers .width-6.driver.yellow:after { background: #FDC743; }
.drivers .width-6.driver.pink:after { background: #F48692; }
.drivers .width-6.driver.grey:after { background: #D9D6CA; }
.drivers .width-6.driver:before { 
    content: "";
    position: absolute;
    width: 200%;
    height: 450px;
    z-index: -1;
}
.drivers .width-6.driver.blue:before { background: #5CB6ED; top: -50px; left: 5px; transform: rotate(-33deg); }
.drivers .width-6.driver.yellow:before { background: #FFD162; top: -150px; left: 165px; transform: rotate(25deg); }
.drivers .width-6.driver.pink:before { background: #EF7B88; top: -60px; left: -55px; transform: rotate(28deg); }
.drivers .width-6.driver.grey:before { background: #D0CDC2; top: -470px; left: -250px; transform: rotate(-28deg); }

.description {
    margin: 0;
    padding: 0;
}
.description .width-6 img {
    width: 80%;
    max-width: 500px;
    display: block;
    margin: 0 auto;
    opacity: 0;
    transition: 0.75s;
}
.description .width-6 img.visible {
    opacity: 1;
}
.description .width-6.wording {
    padding-top: 102px;
}

/* Results screen + questionnaire sections */
.content.core {
    margin-top: 172px;
    margin-bottom: 80px;
    min-height: 60vh;
    position: relative;
}
.content.core h1 {
    font-size: 44px;
    margin-bottom: 24px;
}
.content.core.report {
    border-top: 1px solid #f6f6f6;
    margin-top: 110px;
    padding-top: 20px;
}
.content.core.report .head { margin-bottom: 32px; }
.content.core.report h1:after {
    width: 72px;
    height: 6px;
    background-color: #95CFC0;
    display: block;
    content: "";
}
.content.core.report h2 { font-size: 32px; line-height: 42px; margin-bottom: 0; }
.content.core.report .head h2 { float: right; padding-top: 14px; font-weight: 400; text-transform: none; }
.content.core.report h3 { font-size: 24px; font-family: "Gilroy-Medium", sans-serif; margin: 0 0 22px 0; line-height: 32px; }
.content.core.report .head h3 { margin-left: 32px; float: right; padding-top: 18px; }
#resultsChart {
    width: 100%;
    height: 650px;
    background: url('./../img/graph-bg.png') center center / 100% auto no-repeat;
}
button.download {
    display: block;
    margin: 32px auto;
}

.narrative { padding-left: 95px; margin-bottom: 52px; margin-left: 32px; position: relative; }
.narrative.risk { background: url('./../img/risk-icon.svg') left top 10px / 66px auto no-repeat; }
.narrative.control { background: url('./../img/control-icon.svg') left top 10px / 66px auto no-repeat; }
.narrative.adventurous { background: url('./../img/adventure-icon.svg') left top 10px / 66px auto no-repeat; }
.narrative.data { background: url('./../img/data-icon.svg') left top 10px / 66px auto no-repeat; }

.narrative h5 {
    font-family: "Gilroy-Medium", arial, sans-serif;
    font-size: 20px;
}
.narrative p {
    font-size: 18px;
}
.narrative.ylw:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #FFCA7F;
    border-radius: 10px;
    position: absolute;
    top: 58px;
    left: 46px;
}
.narrative.gr:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #F56474;
    border-radius: 10px;
    position: absolute;
    top: 58px;
    left: 46px;
}
.narrative.gr:after {
    content: "";
    width: 10px;
    height: 20px;
    background: #90C6B8;
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 58px;
    left: 56px;
}

.result .description {
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 32px;
}
.result .description.blue { background: #E7F6FF; }
.result .description.yellow { background: #FFF5DE; }
.result .description.pink { background: #FFE9EB; }
.result .description.grey { background: #F6F6F6; }
.result .description h3 {
    padding: 14px 36px;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 24px;
}
.result .description.blue h3 { background: #5CB6ED; }
.result .description.yellow h3 { background: #FDC743; }
.result .description.pink h3 { background: #EF7B88; }
.result .description.grey h3 { background: #BDBAB0; }
.result .description .wording h5 {
    font-size: 20px;
    text-transform: uppercase;
    font-family: "Gilroy-Bold", arial, sans-serif;
    margin-bottom: 12px;
}
.result .description .wording {
    padding: 16px 36px 24px 36px;
}
.result .description .wording .extra {
    height: 0;
    overflow: hidden;
    transition: 0.75s;
}
.result .description.open .wording .extra {
    height: auto;
}
.result .description .wording button.pseudo-lnk {
    background: none;
    text-transform: none;
    margin-top: 0;
    padding: 0 0 0 20px;
}
.result .description.blue .wording button.pseudo-lnk { background: url('./../img/chevrons/blue.png') left center / 8px auto no-repeat; color: #4D9ECF; }
.result .description.yellow .wording button.pseudo-lnk { background: url('./../img/chevrons/yellow.png') left center / 8px auto no-repeat; color: #DAA41E; }
.result .description.pink .wording button.pseudo-lnk { background: url('./../img/chevrons/red.png') left center / 8px auto no-repeat; color: #BE2839; }
.result .description.grey .wording button.pseudo-lnk { background: url('./../img/chevrons/grey.png') left center / 8px auto no-repeat; color: #000; }
.result .description.open .wording .opener {
    display: none;
}
.result .description.blue.open .wording button.pseudo-lnk { background-image: url('./../img/chevrons/blue-up.png'); }
.result .description.yellow.open .wording button.pseudo-lnk { background-image: url('./../img/chevrons/yellow-up.png'); }
.result .description.pink.open .wording button.pseudo-lnk { background-image: url('./../img/chevrons/red-up.png'); }
.result .description.grey.open .wording button.pseudo-lnk { background-image: url('./../img/chevrons/grey-up.png'); }

.q-menu { clear: both; padding-top: 42px; }
.q-btn {
    font-size: 30px;
    font-family: "Gilroy-Bold", arial, sans-serif;
    border-bottom: none;
    text-transform: uppercase;
    padding: 0;
}
.q-btn.nxt, .q-btn.submit {
    background: url('./../img/chevrons/nxt.svg') right top 1px / 14px auto no-repeat;
    padding-right: 32px;
    float: right;
}
.q-btn.prev {
    background: url('./../img/chevrons/prev.svg') left top 1px / 16px auto no-repeat;
    padding-left: 32px;
    float: left;
}

.q-menu ul { list-style: none; display: block; max-width: 460px; margin: 0 auto; position: relative; }
.q-menu ul li { 
    display: inline-block;
}

ul.report-links {list-style: none;}
ul.report-links li { margin: 8px 0; }
.q-menu ul:before {
    content: "";
    width: 95%;
    height: 2px;
    position: absolute;
    top: 17px;
    left: 8px;
    background: #89C4B5;
    display: block;
    z-index: -1;
}
.q-menu ul li a {
    font-size: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #89C4B5;
    background: #fff;
    border-radius: 15px;
    display: block;
    margin: 0 4px;
    position: relative;
    box-sizing: border-box;
}
.q-menu ul li.current a:before, .q-menu ul li.completed a:before, .q-menu ul li.past a:before {
    content: "";
    width: 18px;
    height: 18px;
    background: #89C4B5;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 10px;
}

.q-menu ul li.past.incomplete a:before{
    background: #EF7B88;
}
.q-menu ul li.current a { border-color: #448575;  }
.q-menu ul li.current a:before { background: #448575; }

.content.q-core {
    margin-top: 120px;
    margin-bottom: 80px;
    min-height: 62vh;
    position: relative;
    overflow-x: hidden;
}

h1.sxn-head {
    max-width: 600px;
    font-size: 42px;
    line-height: 54px;
    float: left;
    margin-top: 60px;
}
h1.sxn-head:after {
    content: "";
    height: 6px;
    width: 62px;
    background: #5CB6ED;
    display: block;
    margin-top: 6px;
}
h1.sxn-head.red:after { background: #EF7B88; }
h1.sxn-head.yellow:after { background: #FDC743; }

ul.cb-labels {
    position: absolute;
    right: 138px;
    top: -120px;
    list-style: none;
    transform: rotate(270deg);
}
ul.cb-labels li {
    margin: 54px 0;
}

.questions {
    margin-top: 35px;
    width: 100%;
    float: left;
}
.questions.blue { margin-top: 88px; }
.questions section {
    padding: 24px 24px 12px 24px;
    margin-bottom: 5px;
    overflow: auto;
}
.questions.blue section { background: #EBF7FF; }
.questions.yellow section { background: #FFF9EA; }
.questions.red section { background: #FFF6F7; }
.questions section p {
    margin-bottom: 0;
    margin-top: 12px;
    max-width: 50%;
    float: left;
}

.answers { float: right; margin: 0; }
.answers .input.radio {
    width: 54px;
    height: 54px;
    display: inline-block; 
    margin: 0 0 0 32px;
    position: relative;
    overflow: hidden;
}
.answers .input.radio label {
    opacity: 1;
    font-size: 0;
}
.answers .input.radio label:before {
    content: "";
    background: #fff;
    width: 54px;
    height: 54px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    padding: 0;
    margin: 0;
    line-height: 0;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
}
.answers .input.radio input {
    position: absolute;
    opacity: 0;
}
.blue .answers .input.radio label:before { border-color: #5CB6ED; }
.red .answers .input.radio label:before { border-color: #EF7B88; }
.yellow .answers .input.radio label:before { border-color: #FFD162; }

.answers .input.radio input:checked + label:before {
    border-width: 15px;
}

/* Login screens */
.login .half {
    width: 50%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    float: left;
}
.login .half.green {
    position: relative;
}
.login .half.green::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    background: linear-gradient(#B4DFD4, #A4D7C9, #95CFC0);
    z-index: -5;
}
.login .half.green .shard-one {
    content: "";
    background: #AADACE;
    width: 150%;
    height: 1000px;
    position: absolute;
    top: -60%;
    left: -80%;
    transform: rotate(-35deg);
    z-index: -3;
}
.login .half.green .shard-two {
    content: "";
    background: #529E8A;
    width: 150%;
    height: 1000px;
    position: absolute;
    top: -45%;
    right: -110%;
    transform: rotate(65deg);
    z-index: -3;
    opacity: 0.25;
}
.login .half.green:after {
    content: "";
    background: #83C7B5;
    width: 150%;
    height: 1000px;
    position: absolute;
    bottom: -95%;
    left: -40%;
    transform: rotate(20deg);
    z-index: -4;
    opacity: 0.4;
}
.login .half.green img {
    max-width: 225px;
    display: block;
    margin: 50% auto;
}

.login-content {
    width: 60%;
    margin: 25% auto;
}
.login-content h1 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 12px;
}
.login-content h2 {
    font-size: 28px;
    text-transform: none;
    opacity: 0.6;
}

/* Admin containers */
.admin-container {
    padding-bottom: 80px;
}
.admin-container .admin-head {
    padding-top: 140px;
    position: relative;
    margin-bottom: 60px;
    overflow: hidden;
}
.admin-container .admin-head:before {
    background: linear-gradient(#B4DFD4, #A4D7C9, #95CFC0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    content: "";
}
.admin-container .admin-head .shard-one {
    content: "";
    background: #AADACE;
    width: 150%;
    height: 300px;
    position: absolute;
    bottom: -100%;
    right: -30%;
    transform: rotate(-8deg);
    z-index: -3;
    opacity: 0.4;
}
.admin-container .admin-head:after {
    content: "";
    background: #83C7B5;
    width: 150%;
    height: 300px;
    position: absolute;
    bottom: -100%;
    left: -40%;
    transform: rotate(10deg);
    z-index: -4;
    opacity: 0.4;
}
.admin-container nav.admin-nav {
    text-align: center;
    margin-bottom: 42px;
}
.admin-container nav.admin-nav ul {
    list-style: none;
}
.admin-container nav.admin-nav ul li {
    display: inline-block;
    margin: 0 20px;
}
.admin-container nav.admin-nav ul li a {
    display: block;
    padding: 5px 18px;
    border-bottom: none;
    font-size: 16px;
    transition: 0.5s;
}
.admin-container nav.admin-nav ul li a:hover {
    opacity: 0.5;
}
.admin-container .content.core-admin {
    min-height: 55vh;
}
.admin-container .content.core-admin h1, .usr-settings h1 {
    font-size: 40px;
    padding-bottom: 5px;
}
.admin-container .content.core-admin h1:after, .usr-settings h1:after {
    content: "";
    height: 6px;
    width: 80px;
    background: #7ABCAB;
    display: block;
}
.admin-container .content.core-admin .btn.minor {
    float: right;
    font-size: 16px;
    padding: 5px 22px;
    margin-top: 0;
}
.admin-container .content.core-admin .btn.minor + .btn.minor {
    margin-right: 22px;
}

.admin-container .content.core-admin table {
    border-collapse: collapse;
    width: 100%;
}
.admin-container .content.core-admin table th, .admin-container .content.core-admin table td {
    padding: 8px;
    border: none;
    margin: 0;
}
.admin-container .content.core-admin table th {
    text-align: left;
    font-family: "Gilroy-Bold", arial, sans-serif;
}
.admin-container .content.core-admin table tr:nth-child(odd) td {
    background: #F2F6F5;
}

.admin-container .content.core-admin table label {
    display: none;
}
.admin-container .content.core-admin table input {
    font-size: 16px;
    margin: 0;
}
.admin-container .content.core-admin table button {
    margin: 0 25px 0 0;
    padding: 12px 25px;
    text-transform: none;
    font-size: 16px;
}

.admin-container .content.core-admin a.action-btn {
    border-bottom: none;
    font-size: 16px;
    background: #DEECE8;
    padding: 8px 15px;
    margin: 0 5px;
    text-align: left;
    transition: 0.5s;
}
.admin-container .content.core-admin a.action-btn.red {
    background: #FFE9EB;
}
.admin-container .content.core-admin a.action-btn:hover {
    opacity: 0.5;
}

.admin-container .content.core-admin .permissions {
    background: #F2F6F5;
    margin: 22px 0 0 0;
    padding: 15px 22px;
}
.admin-container .content.core-admin .permissions h6 {
    font-family: "Gilroy-Bold", arial, sans-serif;
    font-size: 18px;
    margin: 0 0 18px 0;
}

ul.autocomplete {
    border: 1px solid #83C7B5;
    border-top-width: 0;
}

ul.autocomplete a{
    border-bottom: none;
    padding: 8px 22px;
    transition: 0.2s;
    display: block;
}
ul.autocomplete a:hover {
    background: #F2F6F5;
}

ul.autocomplete a.autocomplete-create{
    opacity: 0.5;
    font-style: italic;
}

ul.autocomplete a.autocomplete-create:hover{
    opacity: 0.8;
}

/* User settings header */
.usr-settings { margin-bottom: 80px; min-height: 75vh; }
.usr-settings-head {
    padding-top: 110px;
    position: relative;
    margin-bottom: 60px;
    overflow: hidden;
}

.usr-settings-head:before {
    background: linear-gradient(#B4DFD4, #A4D7C9, #95CFC0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    content: "";
}
.usr-settings-head .shard-one {
    content: "";
    background: #AADACE;
    width: 150%;
    height: 200px;
    position: absolute;
    bottom: -100%;
    right: -30%;
    transform: rotate(-8deg);
    z-index: -3;
    opacity: 0.4;
}
.usr-settings-head:after {
    content: "";
    background: #83C7B5;
    width: 150%;
    height: 300px;
    position: absolute;
    bottom: -100%;
    left: -40%;
    transform: rotate(10deg);
    z-index: -4;
    opacity: 0.4;
}

/* Lightbox */
.cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.35);
    z-index: 1;
}
.cover .hover-box {
    width: 480px;
    background: #fff;
    margin: 35vh auto;
    padding: 28px 36px;
}
.cover .hover-box a.close-lnk {
    cursor: pointer;
    font-size: 0;
    border-bottom: none;
    width: 28px;
    height: 28px;
    background: url('./../img/close-icon.svg') center center / 100% auto no-repeat;
    float: right;
    display: block;
}
.cover .hover-box h2 {
    font-size: 28px;
    padding-bottom: 5px;
}
.cover .hover-box h2:after {
    height: 4px;
    width: 60px;
    background: #7ABCAB;
    display: block;
    content: "";
}
.cover .hover-box p {
    margin-bottom: 12px;
}
p.lnk-wrap {
    word-wrap: break-word;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
}

/* Password strength meter */
#pwForm { position: relative; }
.strength-meter {
    /* display: none; */
    position: absolute;
    top: -280px;
    left: 120px;
    width: 420px;
    background: #fff;
    padding: 18px 25px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    border-radius: 12px;
    font-size: 14px;
    z-index: 1;
    display: none;
}
.strength-meter.display {
    display: block;
}
.strength-meter p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0
}
.strength-meter ul {
    list-style: none;
}
.strength-meter ul li {
    padding-left: 28px;
    line-height: 16px;
    margin: 14px 0;
    background: url('./../img/cross.svg') center left / 16px auto no-repeat;
}
.strength-meter ul li.ticked {
    background: url('./../img/tick.svg') center left / 16px auto no-repeat;
}

/* Filter */
.filter {
    background: #F2F6F5;
    padding: 18px 22px;
    box-sizing: border-box;
}
.filter button {
    padding: 12px 25px;
    font-size: 16px;
}

hr.password-divider{
    border:1px dashed #CBE2DC;
    margin:30px 0 20px 0;
}

/* File upload styling */
.file-upload {
    width: 100%;
    height: 90px;
    border: 2px dotted #CBE2DC;
    box-sizing: border-box;
    position: relative;
    padding: 14px;
    cursor:pointer;
}
.file-upload:hover {
    border-width: 4px;
    padding: 12px;
}
.file-upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.file-upload label {
    text-align: center;
    cursor: pointer;
    font-style: italic;
}

/* Slip into the responsive styling */
@media only screen and (max-width: 1520px) {

    ul.cb-labels { right: 132px; }

    .login .half.green .shard-one {
        top: -80%;
        left: -90%;
    }
    .login .half.green .shard-two {
        right: -140%;
    }
    .login .half.green:after {
        bottom: -125%;
        left: -40%;
    }

}

@media only screen and (max-width: 1320px) {

    .description .width-6.wording { padding-top: 0; }

}

@media only screen and (max-width: 1240px) {

    .mid-hide { display: none; }

    .intro .width-6.wording { width: 65%; margin-top: 200px; }
    .intro .width-6.lady { height: 450px; width: 375px; position: absolute; bottom: 0; right: 22px; }
    .intro .width-6.lady img { height: 100%; width: auto; }

    .drivers .width-6.driver { padding: 65px 56px; }
    .description .width-6 img { padding-top: 120px; }
}

@media only screen and (max-height: 820px) {
    .intro .width-6.wording { width: 65%; margin-top: 200px; }
    .intro .width-6.lady { height: 450px; width: 375px; position: absolute; bottom: 0; right: 22px; }
    .intro .width-6.lady img { height: 100%; width: auto; }
}

@media only screen and (max-width: 1100px) {

    .drivers .width-6.driver h3 { font-size: 26px; line-height: 40px; }

    .description .width-6 img { padding-top: 250px; }

}

@media only screen and (max-width: 980px) {
    .questions section p { max-width: 45%; }
}

@media only screen and (max-width: 960px) {

    .sml-hide {
        display: none;
    }

    header nav { padding: 5px 0; }
    header nav a { padding: 10px 12px; }

    .intro .width-6.lady { height: 350px; width: 275px; right: 22px; }
    .drivers .width-6.driver { padding: 65px 34px; }

    .description .width-6 img { display: none; }
    .description .width-6.wording { padding-bottom: 80px; width: 97.5%; }

    .login .half { width: 95%; }
    .login .half.green { width: 5%; }
    .login .half.green img { display: none; }

}

@media only screen and (max-height: 700px) {
    .intro .width-6.wording { width: 75%; margin-top: 160px; }
    .intro .width-6.wording h1 { font-size: 42px; margin-bottom: 22px; }
    .intro .width-6.lady { height: 300px; width: 225px; }
}
@media only screen and (max-height: 570px) {
    section.intro { height: auto; padding-bottom: 72px; }
}

@media only screen and (max-width: 900px) {

    .questions section p { max-width: 100%; width: 100%; margin-bottom: 32px; }
    ul.cb-labels { display: none; }
    .flash-msg.question-msg { top: 150px; width: 95%; box-sizing: border-box; }
    .questions section p { margin-top: 0; margin-bottom: 22px; float: none; }
    .answers { margin: 0 auto; float: none; width: 485px; }
    .answers .input.radio { height: 100px; width: 76px; margin: 0 10px; }
    .answers .input.radio label { width: 76px; text-align: center; font-size: 12px; line-height: 16px; padding-top: 40px; color: #888; }
    .answers .input.radio label:before { left: 15px; }

}

@media only screen and (max-width: 860px) {
    .drivers .width-6.driver { float: none !important; width: 550px; margin: 32px auto; }

}

@media only screen and (max-width: 780px) {

    h1 { font-size: 44px; line-height: 54px; }

    .core-admin .btn, .filter {
        display: none;
    }

    .core-admin .width-9 {
        width: 97.5%;
    }

    header .width-9, header .width-3 { 
        width: 97.5%;
        float: none;
        text-align: center;
    }
    header { padding-bottom: 0; }
    header nav { text-align: center; }
    .admin-container .admin-head, .usr-settings-head { padding-top: 148px; }
    .admin-container .admin-head nav ul li a { font-size: 14px; }

    section.intro { height: auto; }
    .intro .width-6.wording { width: 85%; margin: 225px auto 120px auto; float: none; }
    .intro .width-6.lady { display: none; }

    .q-menu ul { display: none; }

}

@media only screen and (max-width: 600px) {
    .drivers .width-6.driver { width: 97.5%; margin: 32px auto; }
}

@media only screen and (max-width: 520px) {
    .drivers .width-6.driver p { line-height: 28px; font-size: 16px; }

    header nav ul { margin-left: 0; padding-left: 0; }
    header nav ul li { margin: 0; }
    header nav ul li a { font-size: 13px; }

    .questions section p { margin-bottom: 12px; }
    .answers { width: 100%; }
    .answers .input.radio { display: block; float: none; width: 100%; box-sizing: border-box; height: auto; margin: 12px 0; }
    .answers .input.radio label { width: 100%; box-sizing: border-box; text-align: center; font-size: 14px; text-align: left; line-height: 16px; padding: 0 0 5px 54px; color: #888; }
    .answers .input.radio label:before { left: 0; top: 8px; width: 40px; height: 40px; }
    .answers .input.radio input:checked + label:before { border-width: 12px; }
}